.container__header {
    text-align: center;
}

#hr_header {
    border-color: whitesmoke;
    width: 90%;
    max-width: 1650px;
}

@media (max-width: 1536px) {
    .logo__header>img {
        height: 190px !important;
        /* max-width: 85%; */
    }
}

@media (max-width: 800px) {
    .logo__header>img {
        /* height: 210px !important; */
        max-width: 95%;
        height: auto !important;
        margin-top: 40px !important;
    }
}

/* .text__header>h2 {
    letter-spacing: 2px;
}

.text__header>h4 {
    letter-spacing: 4px;
} */