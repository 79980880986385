.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Garantiza que el contenedor ocupe toda la altura de la ventana */
}

.footer {
    background-color: rgba(248, 249, 250, 0.03);
    text-align: center;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer p {
    margin: 4px 0;
    font-size: 14px;
    color: white;
    letter-spacing: 1px;
    font-weight: lighter;
}

.status-activa {
    background-color: #c8e6c9;
    color: #256029;
}